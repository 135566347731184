/**
 * Recursively flattens a nested array structure into a single-level array.
 * The function can handle nested arrays accessed either by a key or through a custom selector function.
 *
 * @template T - The type of elements in the array
 *
 * @param array - The input array containing nested structures to flatten
 * @param selector - Either a key of T pointing to the nested array, or a function that returns the nested array
 *
 * @returns A flattened array containing all elements from the original nested structure
 *
 * @example
 * // Using with a key
 * interface TreeNode {
 *   id: number;
 *   children: TreeNode[];
 * }
 * const tree: TreeNode[] = [{ id: 1, children: [{ id: 2, children: [] }] }];
 * const flat1 = flattenArrayByKeyRecursive(tree, 'children');
 *
 * // Using with a selector function
 * interface MenuItem {
 *   name: string;
 *   subItems: MenuItem[];
 * }
 * const menu: MenuItem[] = [{ name: 'File', subItems: [{ name: 'New', subItems: [] }] }];
 * const flat2 = flattenArrayByKeyRecursive(menu, item => item.subItems);
 */
export function flattenArrayByKeyRecursive<T>(
  array: T[],
  selector: keyof T | ((item: T) => T[]),
): T[] {
  return array.reduce((acc, item) => {
    const children =
      typeof selector === 'function' ? selector(item) : item[selector];

    if (Array.isArray(children)) {
      return [
        ...acc,
        item,
        ...flattenArrayByKeyRecursive(children as T[], selector),
      ];
    }

    return [...acc, item];
  }, [] as T[]);
}
